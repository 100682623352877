@import url("https://fonts.googleapis.com/css2?family=Rye&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ewert&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: "red";
  zoom: 0.75;
}

.torch {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9); /* Dark overlay */
  pointer-events: none; /* Allow interaction through the overlay */
  mask-image: radial-gradient(circle 100px at 50% 50%, transparent 100px, black 200px);
  mask-size: 100vw 100vh;
  mask-repeat: no-repeat;
  transition: mask-position 0.05s ease;
}

.body2 {
  background-image: url("./assets/frame.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 134vh;
  max-height: 134vh;
  z-index: 1;
  position: relative;
  opacity: 1;
  visibility: visible;
}

.bodyCurtain {
  background-image: url("./assets/MovieCurtain.jpeg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 133vh;
  max-height: 133vh;
}
.bodyContact {
  background-image: url("./assets/virumaandibg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 133vh;
  max-height: 133vh;
}

.body3 {
  background-image: url("./assets/bg2.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 133vh;
  max-height: 133vh;
}

.textnav {
  font-family: "Playfair Display";
}

.texttitle {
  font-family: "Rye";
}

.texthead {
  font-family: "Ewert";
  color: "red";
}

.gradient {
  background: linear-gradient(#faf3e0, #e8cfb0);
}

.image-card {
  background-image: url("./assets/background.png");
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.fade-in {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.8s ease-out, transform 0.8s ease-out;
}

.fade-in.show {
  opacity: 1;
  transform: translateY(0);
}

/* Slide-in Animation */
@keyframes slideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.slide-in-left {
  animation: slideInFromLeft 4s forwards;
}

.slide-in-right {
  animation: slideInFromRight 4s forwards;
}


@media (max-width: 640px) {
  .body3 {
    background-image: url("./assets/bg2.png");
    background-size: cover;
    background-position: top center;
    min-height: 50vh;
    max-height: none;
  }

  .body2 {
    background-image: url("./assets/mobileframe.png");
    background-size: contain;
    background-position: top center;
    min-height: 60vh;
    max-height: none;
  }
}
